import { useRoute } from '#imports';
import { computed } from '#build/imports';

export const useRegistration = () => {
  const route = useRoute();

  const isRegistrationFunnel = computed(() => route.path.startsWith('/register'));
  const isRegistrationStep = computed(() => route.path.startsWith('/register') && !route.path.startsWith('/register/success'));

  return {
    isRegistrationFunnel,
    isRegistrationStep,
  };
};
